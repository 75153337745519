import React, { useState, useEffect } from 'react';

import MainScrollBar from "./MainScrollBar";
import DroowMenu from "../components/header/menu/DroowMenu";
import LoadingPage from "../hooks/LoadingPage";
import CustomCursor from "./CustomCursor";
import axios from 'axios';
import { API_URL } from '../shared/constant';

function Layout(
    {className, children, tag: Tag = "div", activeScrollbar, ...restProps}) {
        const [isBusy, setBusy] = useState(false);
        const [menuContent, setMenuContent] = useState([
            { name: "Home", to: "/" },
            { name: "About", to: "/about-us" },
            {
                name: "Information",
                dropdownMenu: [
                    { name: 'Technical Specification', to: "/technical-specification-wall-tiles" },
                    { name: 'Packing Details', to: "/packing-detail" },
                    { name: 'Tiles Calculator', to: "/tiles-calculator" }
                ]
            },
            {
                name: "Product",
                dropdownMenu: []
            },
            { name: "Catalogue", to: "/catalogue" },
            { name: "Exports", to: "/exporting-countries" },
            { name: "News", to: "/blog" },
            { name: "Contact", to: "/contact-us" },
        ]);

        useEffect(() => {
            axios.post(API_URL.SITE_CATEGORY_SUB_CATEGORY, {}).then(res => {
                if (res.data.data && res.data.data.length) {
                    let menus = menuContent;
                    menus[3].dropdownMenu = res.data.data.map((row) => {
                        // if (row.sub_category && row.sub_category.length) {
                        //     let subMenu = {
                        //         name: row.name,
                        //         dropdownMenu: []
                        //     }
                        //     subMenu.dropdownMenu =  row.sub_category.map((subc) => {
                        //         return {name: subc.name, to: "/products/" + row.slug + "/" + subc.slug + "/collections"}
                        //     })
                        //     return subMenu;
                        // } else {
                            return {name: row.name, to: "/product/" + row.slug}
                        // }
                    });
                    setBusy(true);
                    setMenuContent(menus);
                }
            })
        }, []);

    return (
        <Tag id="main_layout" className={className}>
            { (isBusy && menuContent[3].dropdownMenu.length > 0) ? <DroowMenu hamburger menuContent={menuContent}/> : null }
            <LoadingPage/>
            {activeScrollbar ? <MainScrollBar  {...restProps}>{children}</MainScrollBar> : children}
            <CustomCursor
                duration={0.5}
                durationChangeSize={0.3}
                size={30}
                scale={75}
                ease="power2.out"
            />
        </Tag>
    );
}

export default Layout;