import React from "react";
import Navbar, { Nav } from "../../nav/Navbar";
import Brand from "../../brand/Brand";
import MenuContent from "./MenuContent";

// const menuContent = [
//     { name: "Home", to: "/" },
//     { name: "About", to: "/about-us" },
//     {
//         name: "Information",
//         dropdownMenu: [
//             { name: 'Technical Specification', to: "/technical-specification-wall-tiles" },
//             { name: 'Packing Details', to: "/packing-detail" },
//             { name: 'Tiles Calculator', to: "/tiles-calculator" }
//         ]
//     },
//     {
//         name: "Product",
//         dropdownMenu: []
//     },
//     { name: "Catalogue", to: "/catalogue" },
//     { name: "Exports", to: "/exporting-countries" },
//     { name: "News", to: "/blog" },
//     { name: "Contact", to: "/contact-us" },
// ];

const DroowMenu = ({ hamburger, menuContent }) => {
    
    let $key = 0;
    const getSubMenu = (items) => {
        $key++;
        if (items.dropdownMenu) {
            return (<Nav.Dropdown name={items.name}
                key={$key}>{items.dropdownMenu.map(item => getSubMenu(item))}</Nav.Dropdown>);
        }
        return (
            <Nav.Link to={items.to} key={$key}>{items.name}</Nav.Link>
        );
    }

    return (
        <Navbar hamburger={hamburger}>
            <Navbar.Brand to={"/"}>
                <Brand width="160px" height="70px" />
            </Navbar.Brand>
            <Navbar.Collapse cover="Menu">
                <Nav>
                    {menuContent.map(item => getSubMenu(item))}
                </Nav>
                <MenuContent className="section-margin" />
            </Navbar.Collapse>
        </Navbar>
    );
}

DroowMenu.defaultProps = {
    hamburger: false
}


export default DroowMenu;