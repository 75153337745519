import React from 'react';
import { hydrate, render } from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {store} from './app/store'
import {Provider} from 'react-redux'

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<Provider store={store}>

        <BrowserRouter>
            <App/>
        </BrowserRouter>

    </Provider>, rootElement);
} else {
  render(<Provider store={store}>

        <BrowserRouter>
            <App/>
        </BrowserRouter>

    </Provider>, rootElement);
}

// ReactDOM.render(
//     <Provider store={store}>

//         <BrowserRouter>
//             <App/>
//         </BrowserRouter>

//     </Provider>,
//     document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
