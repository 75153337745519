import React, { useEffect, useState } from 'react';
import { dsnCN } from "../../../hooks/helper";
import { API_URL } from '../../../shared/constant';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function MenuContent({ className }) {
    const [contact, setContact] = useState({});
    const [contactNo, setContactNo] = useState("");
    const [contactTwo, setContactTwo] = useState("");

    useEffect(() => {
        axios.post(API_URL.SITE_CONTACT_INFO, {}).then(res => {
            if (res.data.data) {
                let cn = res.data.data.mobile_number.replace(/ /g, "");
                let cn2 = res.data.data.extra_mobile_number.replace(/ /g, "");
                setContactNo(cn);
                setContactTwo(cn2);
                setContact(res.data.data);
            }
        })
    }, []);

    return (
        <div className={dsnCN('container-content  d-flex flex-column justify-content-center', className)}>
            <div className="nav__info">
                <div className="nav-content">
                    <h5 className="sm-title-block mb-10">Address</h5>
                    <p style={{ color: 'white' }}>
                        {contact.address_one}    <br /> {contact.address_two}
                    </p>
                </div>
                <div className="nav-content mt-30">
                    <h5 className="sm-title-block mb-10">Contact</h5>
                    <p className="links over-hidden mb-1">
                        <a href={'https://wa.me/' + contactNo} target='_blank' rel="noreferrer" style={{ textDecoration: 'none', fontSize: 16 }}><FontAwesomeIcon icon={faWhatsapp} /> {contact.mobile_number}</a>
                    </p>
                    <p className="links over-hidden mb-1">
                        <a href={'https://wa.me/' + contactTwo} target='_blank' rel="noreferrer" style={{ textDecoration: 'none', fontSize: 16 }}><FontAwesomeIcon icon={faWhatsapp} /> {contact.extra_mobile_number}</a>
                    </p>
                    <p className="links over-hidden">
                        <a href={'mailto:' + contact.contact_email} target='_blank' rel="noreferrer" style={{ textDecoration: 'none' }}> {contact.contact_email}</a>
                    </p>
                </div>
            </div>
            {/* <div className="nav-social nav-content mt-30">
                <div className="nav-social-inner p-relative">
                    <h5 className="sm-title-block mb-10">Follow us</h5>
                    <ul className='ps-0'>
                        {contact.facebook && <li>
                            <a href={contact.facebook} target="_blank" rel="nofollow noopener noreferrer">Facebook</a>
                        </li>}
                        {contact.twitter && <li>
                            <a href={contact.twitter} target="_blank" rel="nofollow noopener noreferrer">Twitter</a>
                        </li>}
                        {contact.instagram && <li>
                            <a href={contact.instagram} target="_blank" rel="nofollow noopener noreferrer">Instagram</a>
                        </li>}
                        {contact.linkedin && <li>
                            <a href={contact.linkedin} target="_blank" rel="nofollow noopener noreferrer">Linkedin</a>
                        </li>}
                        {contact.pinterest && <li>
                            <a href={contact.pinterest} target="_blank" rel="nofollow noopener noreferrer">Pinterest</a>
                        </li>}
                        {contact.youtube && <li>
                            <a href={contact.youtube} target="_blank" rel="nofollow noopener noreferrer">Youtube</a>
                        </li>}
                    </ul>
                </div>
            </div> */}
        </div>
    );
}

export default MenuContent;